
$color-primary: hsl(198, 99%, 44%);
$color-primary-dark: hsl(198, 99%, 34%);
$color-primary-darker: hsl(198, 99%, 24%);
$color-primary-darkest: hsl(198, 99%, 18%);
$color-primary-light: hsl(198, 99%, 75%);
$color-primary-lighter: hsl(198, 99%, 85%);
$color-primary-lightest: hsl(198, 99%, 98%);


$color-secondary: hsl(198, 99%, 30%);
$color-secondary-darker: hsl(198, 99%, 24%);
$color-secondary-darkest: hsl(198, 99%, 5%);



$background-image: url('/assets/clients/base/background.jpg');
$logo-image: url('/assets/clients/base/logo.png');
$logo-height: 245px;
