
$color-primary: hsl(198, 99%, 44%);
$color-primary-dark: hsl(198, 99%, 34%);
$color-primary-darker: hsl(198, 99%, 24%);
$color-primary-darkest: hsl(198, 99%, 18%);
$color-primary-light: hsl(198, 99%, 75%);
$color-primary-lighter: hsl(198, 99%, 85%);
$color-primary-lightest: hsl(198, 99%, 98%);


$color-secondary: hsl(198, 99%, 30%);
$color-secondary-darker: hsl(198, 99%, 24%);
$color-secondary-darkest: hsl(198, 99%, 5%);

$color-tertiary: hsl(2, 74%, 60%);

$color-dashboard-1: hsl(83deg, 37%, 63%);
$color-dashboard-2: hsl(172deg, 38%, 57%);
$color-dashboard-3: hsl(2deg, 74%, 60%);
$color-dashboard-4: hsl(358deg, 93%, 73%);
$color-dashboard-5: hsl(34deg, 89%, 71%);

$color-app:hsl(210, 11%, 22%);

$color-success: hsl(120, 38%, 65%);
$color-error: hsl(2, 74%, 60%);
$color-warning: hsl(39 ,100%, 50%);
$color-info: hsl(192, 60%, 49%);

$color-orange: hsl(39, 98%, 50%);

$color-cover-vertical: linear-gradient(0deg, #000, hsla(212, 100%, 17%, 0.6) 30%, transparent);
$color-cover-horizontal: linear-gradient(45deg, #000, hsla(227, 100%, 5%, 0.8) 20%, transparent 88%);
$color-background-app: var(--color-secondary-darkest);

// Neutral
$color-neutral-light: hsl(210, 15%, 92%);
$color-neutral-lighter: hsl(210, 17%, 95%);
$color-neutral-lightest: hsl(210, 14%, 97%);
$color-neutral-mid: hsl(210, 14%, 83%);
$color-neutral-mid-dark: hsl(210, 11%, 71%);
$color-neutral-mid-darker: hsl(210, 7%, 56%);
$color-neutral-mid-light: hsl(210, 14%, 89%);
$color-neutral-dark: hsl(210, 9%, 31%);
$color-neutral-darker: hsl(210, 11%, 22%);
$color-neutral-darkest: hsl(213, 12%, 5%);


$color-grey-darkest: #191C1F;

$base-border-color: #CBD5E1;
$base-border-color-active: #0099DB;
$color-neutral-mid-light: #E7EBF0;
$color-neutral-light: #CBD5E1;

$forum-background-color: #FFFFFF;
$forum-thread-background-color: #F1FBFF;
$forum-threads-text-color: $color-neutral-mid;
$forum-threads-active-text-color: #019DDF;
$forums-active-thread-title-bg-color: #ffffff;
$forums-active-thread-body-bg-color: #ffffff;
$forums-active-thread-footer-bg-color: #ffffff;

$forum-header-bg-color: #ffffff;
$forum-header-text-color: #019DDF;

$forum-footer-bg-color: #ffffff;
$forum-footer-text-color: #019DDF;

$forum-message-reply-text: #808080;
$forum-message-reply-arrow-bg-color: #808080;

$modal-body-bg-color: #525659;

$scrollbar-color: $color-grey-darkest;

//$color-secondary: var(--color-secondary-warning);
$color-secondary-light:  hsl(32, 97%, 62%);
$color-secondary-lighter: hsl(31, 97%, 75%);
$color-secondary-lightest: hsl(31, 97%, 87%);


$pdf-a4-default-width: 695px;
$pdf-a4-default-height: 842px;

$background-image: url('/assets/images/admin/background.jpeg');
$logo-image: url('/assets/images/logo.svg');
$logo-height: 90px;
