$sm-min: 576;
$md-min: 768;
$lg-min: 992;
$xl-min: 1300;

$breakpoint-sm-min: 'screen and (min-width: '+$sm-min+'px)';
$breakpoint-md-min: 'screen and (min-width: '+$md-min+'px)';
$breakpoint-lg-min: 'screen and (min-width: '+$lg-min+'px)';
$breakpoint-xl-min: 'screen and (min-width: '+$xl-min+'px)';

$breakpoint-sm-max: 'screen and (max-width: '+$sm-min+'px)';
$breakpoint-md-max: 'screen and (max-width: '+$md-min+'px)';
$breakpoint-lg-max: 'screen and (max-width: '+$lg-min+'px)';
$breakpoint-xl-max: 'screen and (max-width: '+$xl-min+'px)';

:export {
  sm: $sm-min;
  md: $md-min;
  lg: $lg-min;
  xl: $xl-min;
}

@function extra-small-screen() {
  @return 'screen and (max-width: 575px)';
}

@function min-small-screen() {
  @return 'screen and (min-width: 575.5px)';
}

@function small-screen() {
  @return 'screen and (min-width: 576.5px) and (max-width: 767.5px)';
}


@function min-medium-screen() {
  @return 'screen and (min-width: 768px)';
}

@function medium-screen() {
  @return 'screen and (min-width: 768px) and (max-width: 991.5px)';
}

@function max-medium-screen() {
  @return 'screen and (max-width: 991.5px)';
}

@function min-large-screen() {
  @return 'screen and (min-width: 992px)';
}

@function large-screen() {
  @return 'screen and (min-width: 992px) and (max-width: 1199.5px)';
}

@function extra-large-screen() {
  @return 'screen and (min-width: 1200px)';
}
