.stats-panel {
  width: 100%;
  padding: $spacing-m;

  @mixin empty-content {
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    transition: background ease;
  }

  .subtitle {
    padding: $spacing-s $spacing-m;
    margin-bottom: $spacing-xs;
    font-size: 1.2rem;
    grid-column: 1/3;
    display: flex;
    gap: $spacing-xs;
    align-items: baseline;
    justify-content: center;
    color: var(--color-primary-darker);
    background: #fff;
    box-shadow: 0 4px 6px -6px #5555559c;
    font-weight: 400;
  }

  .dashboard-panel {
    &:empty {
      @include empty-content;
    }

    .highcharts-background {
      fill: transparent;
    }
  }

  .filters-section {
    margin-bottom: $spacing-l;
    background: var(--color-neutral-lightest);
    padding: $spacing-m;

    :is(.filters, .actions, .filter-tags) {
      margin-bottom: $spacing-m;
    }

    .filters {
      display: grid;
      gap: $spacing-m;
      grid-template-columns: repeat(auto-fill, minmax(min(250px), 1fr));
      label {
        text-transform: inherit;
      }
    }

    .filter-tags {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;

      .filter-tag {
        color: #fff;
        padding: 0.75rem 1.5rem;
        text-transform: uppercase;
        font-weight: 500;
        position: relative;
        z-index: 2;

        &:hover {
          cursor: pointer;

          &::before,
          &::after {
            background: $color-error;
          }
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          background: var(--color-secondary);
          border-radius: 50px;
          z-index: -1;
          transform: rotate(-1deg);

          transition: all 0.3s ease;
        }

        &::before {
          left: 0.5rem;
          right: 1rem;
          height: 20px;
          bottom: 0.5rem;
        }

        &:after {
          left: 1rem;
          right: 0.75rem;
          height: 22px;
          bottom: 1rem;
        }
      }
    }
  }

    .section-title{
        color: var(--color-primary-darker);
        border-bottom: 1px solid;
        text-transform: uppercase;
        font-size: 1.2rem;
        padding: $spacing-s $spacing-xs;
    }
    .users-information {
        grid-template-areas:
            "title title title"
            "genre countries countries "
            "activity distribution distribution"
            "session session session"
            "device device2 device2";
            }

    .users-information-hide-chart {
        grid-template-areas:
            "title title title"
            "genre activity activity "
            "country country country"
            "distribution distribution distribution"
            "session session session"
            "device device2 device2";
    }

    .users-information-hide-not-gender{
        grid-template-areas:
            "title title title"
            "activity activity activity"
            "session session session"
            "device device2 device2";
    }

    .users-information-hide-not-age{
        grid-template-areas:
            "title title title"
            "activity distribution distribution"
            "session session session"
            "device device2 device2";
    }

    .users-information-hide-not-gender-country-age{
        grid-template-areas:
        "title title title"
        "activity countries countries"
        "distribution distribution distribution"
        "session session session"
        "device device2 device2";
    }

    .users-information-hide-not-country{
        grid-template-areas:
        "title title title"
        "genre countries countries"
        "activity activity activity"
        "session session session"
        "device device2 device2";
    }

    :is(.users-information, .users-information-hide-chart,
    .users-information-hide-not-gender,
    .users-information-hide-not-age,
    .users-information-hide-not-gender-country-age,
    .users-information-hide-not-country){
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: $spacing-l $spacing-m;
        margin-bottom: $spacing-xl;
        background: #fff;
        padding: $spacing-xs;
        .section-title{ grid-area: title;}
        .users-genre{ grid-area: genre;}
        .users-countries{ grid-area: countries;}
        .users-activity{ grid-area: activity;}
        .users-session{ grid-area: session;}
        .device-sesion{grid-area: device;}
        .device-sesion2{grid-area: device2;}
        .ages-users{grid-area: distribution;}

    .dashboard-panel:empty {
      height: 300px;
    }

    .users-activity,
    .users-session,
    .device-sesion,
    .ages-users,
    .users-countries {
      border: 1px dashed var(--color-neutral-mid-light);
      background: var(--color-neutral-lightest);
      padding: $spacing-xs;
    }
    .users-genre {
      min-width: 380px;
    }

        .device-sesion2{
            display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(600px, 1fr)); */
            grid-auto-rows: 181px;
            gap: 0.5rem;
            grid-template-columns: repeat(2, 1fr);
        }
    }


  .contents-information {
    display: grid;
    gap: $spacing-l;
    margin-bottom: $spacing-xl;
    background: #fff;
    padding: $spacing-xs;

    .active-contents,
    .contents-realized {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: $spacing-m;
      margin-bottom: $spacing-xl;
      align-self: start;

      .subtitle {
        grid-column-end: -1;
      }
    }
  }

  .box-info {
    &:empty {
      min-height: 100px;
      @include empty-content;
    }

    --color: var(--color-secondary);
    &.type--1 {
      --color: var(--color-dashboard-1);
    }
    &.type--2 {
      --color: var(--color-dashboard-2);
    }
    &.type--3 {
      --color: var(--color-dashboard-3);
    }
    &.type--4 {
      --color: var(--color-dashboard-4);
    }
    &.type--5 {
      --color: var(--color-dashboard-5);
    }

    .content {
      display: grid;
      grid-template-columns: 70px 1fr;
      grid-template-rows: repeat(3, auto);

      background: #fff;
      box-shadow: $shadow-elevation-1;
      border-radius: 5px;
      height: 100%;
      overflow: hidden;

      .box-icon {
        color: #fff;
        border: 2px solid;
        border-radius: 5px;
        align-self: stretch;
        align-items: center;
        display: grid;
        justify-content: center;
        grid-column: 1;
        grid-row: 1/4;
        font-size: 2rem;
        padding: $spacing-s;
        background: var(--color);

        img {
          width: 100%;
          filter: brightness(2);
        }
      }

      .title {
        grid-row: 1;
        grid-column: 2;
        padding: $spacing-s $spacing-m;
        display: grid;
        background: #fff;

        .number {
          color: var(--color);
          align-self: end;

          b {
            font-size: 2rem;
            font-weight: 600;
          }

          span {
            font-size: 1.5rem;
            font-weight: 600;
          }
        }

        .box-subtitle {
          color: var(--color-neutral-dark);

          b {
            font-size: 1.25rem;
            font-weight: 600;
          }

          span {
            font-size: 1rem;
            font-weight: 600;
          }
        }
      }

      .name {
        padding: $spacing-xs $spacing-s;
        text-transform: uppercase;
        font-weight: 600;
        grid-row: 3;
        grid-column: 2;
        text-align: right;
        background: var(--color-neutral-lighter);
      }
    }
  }

  .courses-information {
    padding: $spacing-xs;
    background: #fff;

    .daily-chart:empty {
      min-height: 300px;
      @include empty-content;
    }
  }

    .chapters-information{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
        "title title"
        "active realized"
        "chapters chapters"
        "daily daily"
        "times times"
        "nps nps"
        "usersactives usersactives";
        gap: $spacing-l;
        margin-bottom: $spacing-xl;
        background: #fff;
        padding: $spacing-xs;

    .section-title {
      grid-area: title;
    }
    .active-chapters {
      grid-area: active;
    }
    .chapters-realized {
      grid-area: realized;
    }
    .daily-chapters {
      grid-area: daily;
    }

    .daily-chart:empty,
    .chapters-chart:empty {
      min-height: 300px;
      @include empty-content;
    }

    .chapters,
    .chapters-realized,
    .nps,
    .times {
      border: 1px dashed var(--color-neutral-mid-light);
      background: var(--color-neutral-lightest);
      padding: $spacing-xs;
    }

    .chapters {
      grid-area: chapters;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      align-content: start;
      gap: $spacing-l $spacing-xs;
      padding: $spacing-m;
      min-height: 200px;

            &:empty{
                min-height: 300px;
                @include empty-content;
            }
        }

        .nps{
            grid-area: nps;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: $spacing-m;
        }

        .users-actives,
        .nps{
              .course-list ol{
                padding: 0.5rem 1rem;
                list-style-position: inside;

        li {
          display: grid;
          grid-template-columns: 80px 1fr;
          gap: $spacing-s;
          align-items: baseline;

          span {
            &.error {
              --background-color: var(--color-dashboard-3);
            }

                        &.success{
                            --background-color: var(--color-dashboard-1);
                        }

                        background-color: var(--background-color);
                        text-align: center;
                        padding: $spacing-3xs $spacing-2xs;
                        margin-bottom: $spacing-2xs;
                        color: #fff;
                        font-weight: 500;
                        border-radius: 5px;
                        display: inline-block;

                    }
                }
            }
        }

    .times {
      grid-area: times;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $spacing-m;

      .times-chart {
        grid-column: 1/3;
        padding: $spacing-m;

                &:empty{
                    min-height: 300px;
                    @include empty-content;
                }
            }
        }

        .users-actives{
            grid-area: usersactives;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: $spacing-m;
        }
    }

    .users-actives-and-less{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $spacing-l;
        margin-bottom: $spacing-xl;
        background: #fff;
        padding: $spacing-xs;

        .inactives{
            background:var(--color-neutral-lightest) ;
            border: 1px dashed var(--color-neutral-mid-light);

        }

        .course-list ol{
            padding: 0.5rem 1rem;
            list-style-position: inside;

            li{
                display: grid;
                grid-template-columns: 80px 1fr;
                gap: $spacing-s;
                align-items: baseline;

                span {
                    &.error{
                        --background-color: var(--color-dashboard-3);
                    }

                    &.success{
                        --background-color: var(--color-dashboard-1);
                    }

                    &.info{
                        --background-color: var(--color-neutral-mid-dark);
                    }

                    background-color: var(--background-color);
                    text-align: center;
                    padding: $spacing-3xs $spacing-2xs;
                    margin-bottom: $spacing-2xs;
                    color: #fff;
                    font-weight: 500;
                    border-radius: 5px;
                    display: inline-block;

                }
            }
        }
    }

  .courses {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $spacing-m;
    margin-bottom: $spacing-xl;

    .dashboard-panel:empty {
      height: 400px;
    }
  }

  .trained-users {
    display: grid;
    grid-template-columns: 1fr;
    gap: $spacing-l $spacing-m;
    margin-bottom: $spacing-xl;
    background: #fff;
    padding: $spacing-xs;

    .trained-users-chart {
      border: 1px dashed var(--color-neutral-mid-light);
      background: var(--color-neutral-lightest);
      padding: $spacing-xs;
    }

    .dashboard-panel:empty {
      min-height: 350px;
      @include empty-content;
    }
  }

  .unique-logins {
    display: grid;
    grid-template-columns: 1fr;
    gap: $spacing-l $spacing-m;
    margin-bottom: $spacing-xl;
    background: #fff;
    padding: $spacing-xs;

    .unique-logins-chart {
      border: 1px dashed var(--color-neutral-mid-light);
      background: var(--color-neutral-lightest);
      padding: $spacing-xs;
    }
    .dashboard-panel:empty {
      min-height: 350px;
      @include empty-content;
    }
  }

  .courses {
    display: grid;
    grid-template-columns: 1fr;
    gap: $spacing-l $spacing-m;
    margin-bottom: $spacing-xl;
    background: #fff;
    padding: $spacing-xs;

    .courses-chart {
      border: 1px dashed var(--color-neutral-mid-light);
      background: var(--color-neutral-lightest);
      padding: $spacing-xs;
      .dashboard-panel:empty {
        min-height: 350px;
        @include empty-content;
      }
    }
  }

  .ratings {
    display: grid;
    grid-template-columns: 1fr;
    gap: $spacing-l $spacing-m;
    margin-bottom: $spacing-xl;
    background: #fff;
    padding: $spacing-xs;

    .ratings-chart {
      border: 1px dashed var(--color-neutral-mid-light);
      background: var(--color-neutral-lightest);
      padding: $spacing-xs;
      .dashboard-panel:empty {
        min-height: 350px;
        @include empty-content;
      }
    }
  }

  .time {
    display: grid;
    grid-template-columns: 1fr;
    gap: $spacing-l $spacing-m;
    margin-bottom: $spacing-xl;
    background: #fff;
    padding: $spacing-xs;

    .time-chart {
      border: 1px dashed var(--color-neutral-mid-light);
      background: var(--color-neutral-lightest);
      padding: $spacing-xs;

      .dashboard-panel:empty {
        min-height: 350px;
        @include empty-content;
      }
    }
  }

  .filters {
    display: grid;
    grid-template-columns: 1fr;
    gap: $spacing-l $spacing-m;
    margin-bottom: $spacing-xl;
    background: #fff;
    padding: $spacing-xs;

    .filter-category {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: $spacing-l $spacing-m;
      margin-bottom: $spacing-xl;
      background: #fff;
      padding: $spacing-xs;
    }

    .filter-category-chart {
      border: 1px dashed var(--color-neutral-mid-light);
      background: var(--color-neutral-lightest);
      padding: $spacing-xs;
    }
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

#exportModal .ts-dropdown {
    width: auto;
    min-width: 100%;
}
