.course-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: $spacing-m;
    margin-bottom: $spacing-xl;

    @mixin empty-content{
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
        transition: background ease;
    }

    .box-info{
        &:empty{
            min-height: 100px;
            @include empty-content;
        }

        --color: var(--color-secondary);
        &.type--1{ --color: var(--color-dashboard-1)}
        &.type--2{ --color: var(--color-dashboard-2)}
        &.type--3{ --color: var(--color-dashboard-3)}
        &.type--4{ --color: var(--color-dashboard-4)}
        &.type--5{ --color: var(--color-dashboard-5)}

        .content{
            display: grid;
            grid-template-columns: 70px 1fr;
            grid-template-rows: repeat(3, auto);

            background: #fff;
        
            border-radius: 5px;
            height: 100%;
            overflow: hidden;

            .box-icon{
                color: #fff;
                border-radius: 5px;
                align-self: stretch;
                align-items: center;
                display: grid;
                justify-content: center;
                grid-column: 1;
                grid-row: 1/4;
                font-size: 2rem;
                padding: $spacing-s;
                background: var(--color);

                img{
                    width: 100%;
                    filter: brightness(2);
                }
            }

            .title{
                grid-row: 1;
                grid-column: 2;
                padding: $spacing-s $spacing-m;
                display: grid;
                background: #fff;

                .number {
                    color: var(--color);
                    align-self: end;

                    b{
                        font-size: 2rem;
                        font-weight: 600;
                    }

                    span{
                        font-size: 1.5rem;
                        font-weight: 600;
                    }
                }

                .box-subtitle {
                    color: var(--color-neutral-dark);

                    b{
                        font-size: 1.25rem;
                        font-weight: 600;
                    }

                    span{
                        font-size: 1rem;
                        font-weight: 600;
                    }
                }
            }


            .name{
                padding: $spacing-xs $spacing-s;
                text-transform: uppercase;
                font-weight: 600;
                grid-row: 3;
                grid-column: 2;
                text-align: right;
                background: var(--color-neutral-lighter);
            }
        }
    }
}