
.chapter-type-selector-wrapper {
    padding: 0 1rem;
}

.chapter-type-selector-header {
    label {
        color: var(--form-label-color);
        font-size: var(--font-size-base);
        font-weight: 500;
        margin: 0;
        padding: 0 0 8px;
    }

    .chapter-type-selector-filters {
        .selected {
            background-color: var(--color-primary);
        }
    }
}

.chapter-type-selector {
    border: 1px solid var(--color-primary);
    border-radius: 3px;
    padding: 2rem;
    background: #fff;
    margin: 1rem 0;

    .chapter-type-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        column-gap: 1rem;
        row-gap: 0.5rem;

        .chapter-type-group {

            input[type="radio"] {
                display: none;
            }

            .chapter-type-icon path {
                fill: #cbd5e1;
            }

            input[type="radio"]:checked + label {


                color: #fff;
                background: var(--color-primary);

                .chapter-type-icon path {
                    fill: #fff;
                }

                border-color: var(--color-primary);
            }

            label {
                display: grid;
                /* flex-direction: column; */
                cursor: pointer;
                justify-items: center;
                color: #777;
                margin: 0;
                padding: 1rem 0;
                border: 1px solid #cbd5e1;
                border-radius: 3px;
                height: 100%;
            }

            .chapter-type-icon svg {
                height: 80px;
                padding-top: 0.5rem;
            }
        }
    }
}

.selected-chapter-type-info {
    &.hidden {
        display: none;
    }

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
    gap: 1rem;

    h3 {
        grid-area: 1 / 1 / 2 / 3;
        margin-top: .5rem;
        color: var(--color-primary);
        font-size: 1.5rem;
        font-weight: bold;
    }

    h4 {
        grid-area: 2 / 1 / 3 / 3;
        border-bottom: 1px solid #ddd;
        font-size: 1.2rem;
        font-weight: bold;
        padding: 0 0 1rem;
        margin: 0 0 1rem;
    }

    .selected-chapter-type-description {
        grid-area: 3 / 1 / 4 / 2;
    }

    .selected-chapter-type-thumbnail {
        grid-area: 3 / 2 / 4 / 2;

        img {
            max-width: 100%;
        }
    }

}

 .modal {
     position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    backdrop-filter: blur(3px);

     .modal-header{
       border-bottom: none !important;
       /*  .modal-title{
            color: #fff
        } */
    }
}

input[id=Chapter_stateRedirect]{
    display: none;
        ~label{
            display: none;
        }
}

//.card{
//    .card-body{
//        background: #fff !important;
//        img{
//            opacity: 0.2;
//            width: 13rem;
//        }
//    }
//}

.question_form_answers{
    display: none;
}

fieldset{
    display: none;
}

.chapter-image-selector {
    .ea-vich-image {
        display: flex;
        flex-flow: column;
        .preview-image {
            order: 0;
            width: 450px;
            height: 300px;
            //border: 1px solid #212121;
            display: none;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            cursor: pointer;
            box-shadow: 0 0 0 4px var(--white),0 0 4px 3px var(--gray-600);
            margin: 6px 4px 12px;

            &.with-image {
                display: block;
            }
        }
        .ea-vich-image-actions {
            order: 1;
            max-width: 300px;
            .input-file-container {
                width: 100%;
            }
        }
        .small {
            order: 2;
        }
    }
}

.has-error-select {
    border: 1px solid red !important;
    animation: movimiento 2s ;
}


@keyframes movimiento {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(30px);
    }
    100% {
      transform: translateX(0);
    }
  }