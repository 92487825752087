$spacing-3xs: 0.15rem;
$spacing-2xs: 0.25rem;
$spacing-xs: 0.5rem;
$spacing-s: 0.75rem;
$spacing-m: 1rem;
$spacing-l: 1.5rem;
$spacing-xl: 2rem;
$spacing-2xl: 3rem;
$spacing-3xl: 4rem;
$spacing-4xl: 6rem;
$spacing-5xl: 8rem;