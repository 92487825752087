#app.VueApp {
  .content-body {
    padding: 0 !important;
  }

  .TableContainer {
    overflow: auto;
  }
}

.alertify .ajs-dialog {
  transition: all ease-in-out .3s;
}

.alertify .ajs-dialog.static {
  transform: scale(1.03);
}

.alertify .ajs-dimmer, .alertify .ajs-modal {
  backdrop-filter: blur(3px) !important;
}

.alertify .ajs-header {
  background-color: var(--gray-50) !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-top-left-radius: calc(0.3rem - 1px) !important;
  border-top-right-radius: calc(0.3rem - 1px) !important;
}

.alertify .ajs-body {
  background-color: var(--white) !important;
}

.alertify .ajs-footer {
  background-color: var(--gray-50) !important;
  border-top: 1px solid #dee2e6 !important;
  border-bottom-right-radius: calc(0.3rem - 1px) !important;
  border-bottom-left-radius: calc(0.3rem - 1px) !important;
}

.alertify .ajs-footer .ajs-buttons .ajs-button {
  display: inline-block !important;
  font-weight: 400 !important;
  text-align: center !important;
  vertical-align: middle !important;
  user-select: none !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  border: 0 !important;
  box-shadow: 0 0 transparent !important;
}

.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
  background-color: var(--color-primary) !important;
  color: #ffffff !important;
}

.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-cancel {
  background-color: var(--color-danger) !important;
  color: #ffffff !important;
}


.form-group.required {
  label::after {
    content: '*';
    color: red;
  }
}
@mixin avatar {
  // Image used as background
  width: 80px;
  height: 80px;
  background-color: #d8eef8;
  border-radius: 50%;
  margin: auto;
  background-position: center center;
  background-size: cover;
}

@mixin nav-bar-style {
  .nav-link {
    color: #707070;
    &.active {
      border-color: #CBD5E1 #CBD5E1 #ffffff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      color: #1E293B;
    }
  }

  .tab-content {
    background-color: #FFFFFF;
    border-right: 1px solid #CBD5E1;
    border-bottom: 1px solid #CBD5E1;
    border-left: 1px solid #CBD5E1;

    .tab-pane {
      padding: 1rem;
      @media #{min-medium-screen()} {
        padding: 1rem 3rem;
      }
    }
  }
}

@mixin boxed-selector {
  $selector-width: 50px;
  $selector-height: 50px;

  display: flex;
  flex-flow: row wrap;
  width: 100%;

  .selector-title {
    color: #212121;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    padding: 0 0 8px;
  }

  .selector-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    border: 1px solid $base-border-color;
    background-color: #FFFFFF;

    button.selector {
      text-transform: uppercase;
      width: $selector-width;
      height: $selector-height;
      border: 1px solid $base-border-color;
      border-radius: 5px;
      margin: 5px;
      color: $base-border-color;
      background-color: #ffffff;
      font-size: 18px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      span {
        font-size: 12px;
      }

      &.active {
        border: 1px solid $base-border-color-active;
        color: $base-border-color-active;
      }
    }
  }
}

@mixin mixin-forum-button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  background-color: transparent;
  color: #9e9e9e;
  border: none;
  font-size: 16px;
  &:hover {
    border: none;
    color: #8b8b8b;
  }
}

@mixin mixin-forum-input {
  width: 100%;
  border-radius: 5px;
  height: 40px;
  background-color: #ffffff;
  color: #019DDF;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #019DDF;
  outline: none !important;

  &:focus {
    border: 2px solid #019DDF;
  }
  &:hover {
    border: 2px solid #019DDF;
  }
}

@mixin mixin-post-reply {
  background-color: #403f3f;
  padding: 0.25rem 1rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: 0;
  color: #9e9e9e;
  display: flex;
  flex-flow: row;
  gap: 0.5rem;

  .--title {
    color: #019DDF;
  }

  .--button-close {
    @include mixin-forum-button;
    margin-left: auto;
  }
}

@mixin post {
  position: relative;
  display: grid;
  grid-template-columns: [user] 50px [content] auto;
  margin: 22px 0 0 0;
  column-gap: 1rem;
  background-color: #ffffff;
  padding: 0.25rem 0.75rem;

  .reply-to-box {
    grid-column-start: 1;
    grid-column-end: 3;
    display: grid;
    grid-template-columns: 25px auto;
    gap: 0.5rem;
    position: relative;
    padding-left: 30px;
    margin-left: 25px;
    cursor: pointer;

    .reply-text {
      color: $forum-message-reply-text;
    }

    &:before {
      content: "";
      width: 2px;
      height: 15px;
      background-color: $forum-message-reply-arrow-bg-color;
      position: absolute;
      top: 10px;
      left: 0;
    }

    &:after {
      content: "";
      width: 30px;
      height: 2px;
      position: absolute;
      top: 10px;
      left: 0;
      background-color: $forum-message-reply-arrow-bg-color;
    }

    .reply-avatar {
      @include avatar;
      width: 25px !important;
      height: 25px !important;
    }

    .reply-message {
      font-size: 12px;
    }

    &:hover {
      .reply-message {
        color: #019DDF;
      }
    }
  }

  .actions-box {
    position: absolute;
    top: -20px;
    right: 20px;
    display: none;
    border-radius: 10px;
    background-color: #837a83;
    flex-flow: row nowrap;
    align-items: center;
    justify-self: flex-end;
    border: 1px solid #212121;

    button:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    button:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    button {
      font-size: 14px;
      cursor: pointer;
      width: 30px;
      height: 30px;
      border: none;
      padding: 0.15rem;
      background-color: transparent;
      color: #ffffff;

      &:hover {
        border: none;
        background-color: #645e64;
      }
    }
  }

  &:hover {
    background-color: #ebeaea;

    .actions-box {
      display: flex;
    }
  }

  .user-avatar {
    @include avatar;
    width: 50px !important;
    height: 50px !important;
  }

  .message {
    width: 100%;
    display: flex;
    flex-flow: column;
    &--header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      .username {
        font-size: 16px;
        font-weight: bold;
        color: $color-neutral-darkest;
        flex-grow: 1;
        text-align: left;
      }
      .date {
        font-size: 12px;
        color: #808080;
        margin-left: auto;
      }
    }
    &--body {
      font-size: 16px;
      color: $color-neutral-darkest;
    }
  }
}

@mixin grid-default-item-style {
  .Item {
    cursor: pointer;
    padding: 0.25rem;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid $base-border-color;

    &--content {
      background-size: cover;
      background-position: center center;
      height: 150px;
      position: relative;
      display: flex;
      color: #ffffff;
      width: 100%;

      > span {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 23px;
        color: #ffffff;
        cursor: pointer;
        margin: 0.25rem;
      }

      p {
        font-size: 17px;
        color: #FFFFFF;
        width: 100%;
        overflow: hidden;
        text-shadow: 1px 1px 3px #000000;
        background: linear-gradient(0deg, rgba(2,0,36,0.3) 0%, rgba(129,129,129,0.3) 32%, rgba(129,129,129,0.3) 69%, rgba(4,4,4,0.3) 100%);
        height: 100%;
        display: flex;

        span {
          margin: auto 0.5rem 0.5rem 0.5rem;
          color: #ffffff;
        }
      }
    }
    &--footer {
      margin-top: 0.5rem;
    }
  }
}

@mixin mixin-scrollbars {
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(33,33,33,0.0);
  }

  ::-webkit-scrollbar-thumb {
    background: #9a9a9a;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #b0b0b0;
  }
}
