@import "./assets/css/config/_variables.scss";@import "./assets/clients/base/_variables.scss";@import "./assets/css/config/global.scss";
@import './vendor';

@import './config/_breakpoints';
@import './config/_spacing';
@import './config/_shadows';
@import './config/_fonts';

@import './config/root';

@import "./results";
@import "./stats";
@import "./course";
@import "./chapterType";


.form-check:not(.form-switch) > input[type=checkbox].form-check-input + label.form-check-label {
    margin-left: 1.5rem;
    position: relative;
    top: -3px;
}

.user-details {
    display: flex;
}
// @import './config/_fonts';


html {
    background: #{$background-image} fixed;
    background-size: cover;
    height: 100%;
}

body {
    height: 100vh;
    background-color: transparent;
    // font-family: var(--font-family-primary);

    &.ea {
        background-color: #00285740;
        height: unset;
        min-height: 100%;
    }
}

body:not(.ea-content-width-full) .content-wrapper {
    max-width: 100%;
}


a{
    color: var(--color-primary-dark);
    transition: all 0.3s ease;

    &:hover{
        color: var(--color-primary);
    }
}

#login .logo {
    background: transparent $logo-image no-repeat center center / contain;
    height: $logo-height;
    width: 100%;
}

#login form {
    background-color: var(--color-primary-lightest);
    border-radius: 1rem;
    width: 50%;
    margin: auto;
    padding: 3%;

    img {
        max-width: 100%;
        margin: 0 auto;
        display: block;
    }

    a.button {
        width: 100%;
        border-radius: 5px;
        padding: 5px;
        font-size: 18px;
        cursor: pointer;
        margin: 12px 5px;
        height: 3rem;
        display: block;
        background-color: $color-primary;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
    }

    @media screen and (max-width: 992px) {
        width: 80%;
    }
}

// ----
.ea-sidebar-width-normal {// .sidebar {
    box-shadow: 20px 5px 10px 4px #00000061;
}

.sidebar {
    background: none;
    left: 0!important;
    padding-right: 0!important;
    padding-left: var(--sidebar-padding-left)!important;
}

.ea-sidebar-width-compact .sidebar {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 20px;
        right: 0;
        height: 10px;
        background: linear-gradient(200deg, #00000061, transparent);
    }
}

#sidebar-resizer-handler {
    background: var(--color-neutral-mid-light);
    right: 100%;
    z-index: 998;
    top: 55px;
}

.navbar-custom-menu .user {
    color: #ffffff;
}

.main-header .content-top {
    flex-grow: 1;
    justify-content: flex-start;
    padding-left: 10px;
    box-shadow: none;
    padding-left: 3px;

    .content-search {
        margin-right: auto;
    }
}

.user-menu-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .popover {
        max-width: 210px;

        .user-details {
            display: block;
            gap: 1rem;
        }

        .user-menu .user-action {
            white-space:nowrap
        }
    }
}


.wrapper {
    display: flex;
    flex-flow: row wrap;
    gap: 0 3px;
    margin: 0 auto;
    max-width: 1700px;
    padding-top: 20px;
    margin: auto;
    max-width: 1700px;

    >.content-wrapper {
        grid-template-columns: auto;
    }


    .main-header {
        width: 100%;
        display: flex;
    }


    .content-header {
        background-color: var(--color-neutral-darker);
        border-radius: 5px 5px 0 0;
        flex-direction: initial;
        padding: 0.5rem 1rem;
        min-height: 54px;
        margin-bottom: 0;

        &::before,
        &::after {
            background-color: transparent;
        }

        .content-header-title {
            align-self: center;

            .title {
                text-transform: uppercase;
                color: #fff;
                font-size: var(--font-size-xl)!important;

                a{
                    color: #fff;
                    transition: all 0.3s ease;

                    &:hover{
                        color: var(--color-primary);
                    }
                }
            }
        }
    }

    .main-sidebar {
        background-color: transparent;
        width: var(--sidebar-max-width);
        padding: 0;
        position: sticky !important;
        height: max-content;
        // top: 52px;
        margin-top: 55px;
        z-index: 999;

        #main-menu {
            // position: fixed;
            // width: var(--sidebar-max-width);
            width: 100% !important;
            // padding-top: 50px;

            .menu {
                background: var(--color-neutral-dark);
                border-radius: 5px 0 0 5px;
                // overflow: hidden;
                margin-bottom: 0;
            }

            .menu .menu-header {
                text-transform: none;
                .menu-header-contents {
                    display: flex;
                    align-items: center;
                }
            }

            .menu .menu-item {
                padding: 0 !important;
                margin: 0;

                .submenu-toggle-icon {
                    color: var(--color-neutral-light);
                    right: 10px;
                    top: 0;
                }

                &.active a {
                    background: var(--color-neutral-lighter) !important;
                    color: var(--color-primary);
                }

                a {
                    padding: 0.75rem;
                    color: var(--color-neutral-light);
                    background: var(--color-neutral-dark);
                    border-radius: 5px 0 0 5px;
                    display: grid;
                    grid-template-columns: auto 1fr auto;
                    gap: 0.25rem;
                    position: relative;
                    transition: all 0.3s ease;

                    i {
                        position: inherit;
                        color: var(--color-neutral-light);
                    }

                    &:hover {
                        background-color: var(--color-primary-darkest);
                        text-decoration: none;
                    }
                }

                .submenu {
                    padding-left: 0.25rem;

                    .menu-item:hover a {
                        background: var(--color-primary-dark);
                    }

                    a {
                        border-radius: 0;
                        // background: var(--color-neutral-mid-darker);
                        background: hsl(210deg, 9%, 41%);
                        align-items: center;
                    }
                }
            }
        }
    }

    .content-wrapper {
        position: relative;
        flex-grow: 1;
        margin-top: 0;
        background: var(--color-neutral-lightest);
        border-radius: 5px;
        width: calc(99% - var(--sidebar-max-width));
        padding-left: 0;
        padding-right: 0;
    }
}

body.ea-sidebar-width-compact .wrapper {
    grid-template-columns: 70px 1fr;

    .main-sidebar {
        &,
        #main-menu {
            width: 70px;
        }
    }

    #main-menu {
        .menu .menu-item:hover a {
            background: var(--color-primary-darker);
        }

        .menu li:hover {
            background: transparent;
        }

        :not(.submenu) > li:hover {
            & > a span {
                // background: var(--color-primary-darker);
                width: 100%;
                text-transform: uppercase;
                display: grid;
                align-items: center;
                padding: 0 0.5rem;
                top: 0;
                bottom: 0;
                font-weight: 500;
            }

            .submenu-toggle > span {
                position: absolute;
            }

            .submenu {
                width: 100%;
                top: 100%;
                padding: 0;

                .menu-item:not(.active):hover {
                    a {
                        background: var(--color-primary-dark) !important;
                    }

                    // .menu-icon{
                    //   color: var(--color-primary);
                    // }
                }

                .menu-item span {
                    top: 0;
                    bottom: 0;
                    // line-height: 3;
                    background-color: transparent;
                }

                a {
                    padding: 0 0.75rem !important;
                    background: hsl(210deg, 9%, 41%);

                    .menu-icon {
                        left: 0;
                        width: 25px;
                    }
                }
            }

            .submenu,
            span:not(.menu-item-label) {
                left: 62px;
            }

            .submenu {
                box-shadow: $shadow-elevation-3;
                margin-left: 0;
            }
        }

        .menu li {
            .menu-icon {
                font-size: 18px;
            }

            .submenu-toggle-icon {
                display: none;
            }
        }
    }
}

@media (min-width: 1024px) {
    body.ea-sidebar-width-compact #main-menu .menu li:hover .submenu span {
        line-height: 3;
    }
}

@media screen and (max-width: 992px) {
    .main-sidebar {
        display: none;
    }
    .ea-mobile-sidebar-visible {
        .wrapper .main-sidebar {
            display: block;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            margin-top: 38px;

            .sidebar {
                background: #484f56;
                padding-left: 0 !important;

                #main-menu .submenu .menu-item a {
                    padding: 0.5rem !important;
                }

                // #main-menu{
                //   .menu-item {
                //     width: 100%!important;
                //     &.has-submenu:not(.expanded) .submenu {
                //       max-height: initial;
                //     }
                //   }
                //   .menu .submenu {
                //     display: block;
                //   }
                //   .submenu-toggle > span{
                //     display: block!important;
                //   }
                //   .submenu{
                //     position: relative!important;
                //     left: 0!important;
                //     box-shadow: none!important;
                //     margin-left: 0!important;
                //     .menu-item a{
                //       padding: 0.5rem!important;
                //       // background: transparent;
                //     }
                //   }
                // }
            }
        }
    }

    .modal-backdrop.show {
        opacity: 0.5;
        z-index: 997;
    }
}

body.ea-sidebar-width-compact:not(.ea-mobile-sidebar-visible) #main-menu .menu {
    li:hover {
        background: var(--body-bg);
        border-radius: var(--border-radius);
        width: 100%;
        z-index: 1040;

        .submenu-toggle > span {
            // display: block;
            display: block !important;
        }

        .submenu {
            display: block;
            position: absolute;
            max-height: initial !important;
        }
    }

    .submenu {
        display: none;
    }

    .submenu-toggle > span {
        display: none;
    }
}

section#main {
    padding: 1rem;
}

.content-panel {
    background: transparent;
    box-shadow: none;
}

.table {
    thead {
        th {
            vertical-align: middle;
        }

        th:first-child {
            span {
                padding-top: 0;
            }
        }
    }
}

.table {
    th {
        font-weight: 500;
    }

    th,
    td {
        vertical-align: middle;
    }

    // td.actions {
    //   display: grid;
    //   gap: $spacing-2xs;
    //   grid-template-columns: repeat(3, auto);
    //   justify-content: flex-end;
    //   grid-auto-columns: auto;
    // }

    td.actions a {
        // background: whitesmoke;
        display: block;
        width: 100%;
        text-align: center;
        padding: $spacing-2xs $spacing-m;
        margin-bottom: $spacing-2xs;
        border-radius: 3px;
    }
}

.datagrid td.actions a:not(.dropdown-item) + a:not(.dropdown-item) {
    margin-left: 0;
}

.text-primary {
    color: var(--color-primary) !important;
}

.badge-primary {
    background-color: var(--color-primary-lighter) !important;
    color: var(--color-primary-darkest) !important;
}

.nav-link {
    color: var(--color-neutral-mid-darker);

    &:hover {
        color: var(--color-neutral-dark) !important;
    }
}

.btn {
    border: 0 !important;
    box-shadow: 0 0 transparent;

    &:hover,
    &:focus {
        box-shadow: none !important;
    }
}

.btn-default {
    background: var(--color-neutral-lighter);

    &:hover {
        background: var(--color-neutral-light);
    }
}

.btn-primary {
    background: var(--color-primary);

    &:hover {
        background: var(--color-primary-dark) !important;
        box-shadow: 0 0 2px var(--color-primary-dark) !important;
    }

    &:disabled {
        background: var(--color-neutral-mid-dark) !important;
    }
}

.btn-secondary {
    color: #fff;
    background-color: hsl(208, 7%, 46%);

    &:hover,
    &:focus,
    &:active {
        background: hsl(208, 7%, 32%) !important;
        box-shadow: 0 0 2px hsl(208, 7%, 32%) !important;
        color: #fff !important;
    }
}

.btn-danger,
.action-delete {
    background: hsl(354, 70%, 54%);
    color: #fff !important;

    &.pr-0 {
        padding-right: 0.75rem !important;
    }

    &:hover {
        background: hsl(354, 70%, 44%) !important;
        box-shadow: 0 0 2px hsl(354, 70%, 44%) !important;
        color: #fff !important;
    }

    &.text-danger:hover {
        color: #fff !important;
    }
}

.course-chapters {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: $spacing-l $spacing-s;

    .chapter {
        display: grid;
    }

    .card-body-chapter {
        display: flex;
        background: #fff !important;
        height: auto;
        flex-direction: column;
        justify-content: space-between;
    }
}

.page-item {
    .page-link {
        color: var(--color-neutral-dark);

        &:hover {
            border-color: transparent;
            background: var(--color-primary-lighter);
            color: var(--color-neutral-dark);
        }

        &:focus {
            z-index: 3;
            outline: 0;
            box-shadow: none;
        }
    }

    &.active .page-link {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
    }

    &.disabled .page-link {
        color: var(--color-neutral-mid-dark);
        pointer-events: none;
        cursor: auto;
        background-color: transparent;
        border-color: transparent;
    }
}

.stats {
    display: grid;
    grid-template-columns: 400px 1fr;
    margin-bottom: 2rem;
    gap: 1rem;

    aside {
        display: grid;
        gap: $spacing-s;
    }

    .subtitle {
        text-transform: uppercase;
        padding: $spacing-xs;
        border-bottom: 1px solid;
        font-size: 1.2rem;
        grid-column: 1/3;
    }

    @mixin empty-content($min-height) {
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        border-radius: 5px;
        background-size: 200% 100%;
        min-height: $min-height;
        animation: 1.5s shine linear infinite;
        transition: background ease;
    }

    .box-info {
        max-width: 400px;

        &:empty {
            @include empty-content(150px);
        }

        @each $k, $i in (1: 1, 2: 2, 3: 5) {
            &:nth-child(#{$k}) .content {
                .box-icon {
                    background: var(--color-dashboard-#{$i});
                }
            }
        }

        &:nth-child(1) .content .box-content {
            @each $i, $lightness in (1 -25%, 2 0%) {
                $color: adjust-color($color-dashboard-1, $lightness: $lightness);
                .chart
                .highcharts-series-group
                .highcharts-series
                path:nth-child(#{$i}) {
                    fill: $color;
                }

                .content-row:nth-child(#{$i}) .value {
                    color: $color;
                }
            }
        }

        &:nth-child(2) .content .box-content {
            @each $i, $lightness in (1 -10%, 2 10%) {
                $color: adjust-color($color-dashboard-2, $lightness: $lightness);
                .chart
                .highcharts-series-group
                .highcharts-series
                path:nth-child(#{$i}) {
                    fill: $color;
                }

                .content-row:nth-child(#{$i}) .value {
                    color: $color;
                }
            }
        }

        &:nth-child(3) .content .box-content {
            @each $i, $lightness in (1 -20%, 2 5%) {
                $color: adjust-color($color-dashboard-5, $lightness: $lightness);
                .chart
                .highcharts-series-group
                .highcharts-series
                path:nth-child(#{$i}) {
                    fill: $color;
                }

                .content-row:nth-child(#{$i}) .value {
                    color: $color;
                }
            }
        }

        .content {
            display: grid;
            grid-template-columns: 70px 1fr;
            grid-template-rows: repeat(2, auto);
            background: linear-gradient(40deg, white, #ffffff00);
            box-shadow: $shadow-elevation-2;
            border-radius: 5px;
            overflow: hidden;

            .box-icon {
                background: var(--color-secondary);
                color: #fff;
                border: 2px solid;
                border-radius: 5px;
                align-self: stretch;
                align-items: center;
                display: grid;
                justify-content: center;
                grid-row: 1/3;
                font-size: 2rem;
                padding: $spacing-s;
            }

            .name {
                padding: $spacing-xs $spacing-s;
                text-transform: uppercase;
                grid-row: 1;
                background: var(--color-neutral-lightest);

                .number {
                    font-size: 1.2rem;
                }
            }

            .box-content {
                padding: $spacing-l $spacing-s;
                grid-row: 2;
                display: grid;

                grid-template-columns: 1fr 2fr;
                grid-template-rows: repeat(2, 1fr);
                gap: 0 $spacing-s;
                background: #fff;

                .content-row {
                    grid-column: 2;

                    &:nth-child(2) {
                        .value {
                            font-size: 1.2rem;
                            font-weight: 500;
                        }
                    }

                    &:nth-child(1) {
                        border-bottom: 1px solid var(--color-neutral-mid-darker);
                        font-weight: 500;
                        font-size: 1.2rem;

                        .value {
                            font-size: 1.5rem;
                        }
                    }
                }

                .chart {
                    grid-row: 1/3;
                    grid-column: 1;
                    pointer-events: none;

                    .highcharts-background {
                        fill: transparent;
                    }

                    .highcharts-series-group {
                        transform: scale(2.5) translate(-28px, -20px);

                        .highcharts-series path {
                            stroke: none;
                        }
                    }
                }
            }
        }
    }

    .stats-chart {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto 1fr;
        gap: $spacing-s;

        .highcharts-background {
            fill: transparent;
        }

        .content {
            &:empty {
                @include empty-content(80px);
            }

            .content-row {
                display: grid;
                grid-template-columns: auto auto;

                .value {
                    font-size: 2.5rem;
                }

                .text {
                    text-transform: uppercase;
                    font-weight: 500;
                    justify-self: start;
                    padding: $spacing-xs;
                    color: var(--color-neutral-darker);
                }
            }
        }

        .times-chart:empty {
            @include empty-content(400px);
        }

        .chapter-times {
            display: grid;
            align-content: start;
            gap: $spacing-s;

            .chapter-time {
                border-bottom: 1px solid var(--color-neutral-mid-dark);
                display: grid;
                grid-template-columns: auto 1fr auto;
                align-items: center;
                gap: $spacing-s;
                padding: $spacing-2xs;
                position: relative;

                img {
                    width: 25px;
                }

                .type {
                    text-transform: uppercase;
                }

                .time {
                    font-weight: 500;

                    .line {
                        position: absolute;
                        bottom: -5px;
                        right: 0;
                        height: 10px;
                        width: 0;
                        transition: all 0.5s ease;
                    }
                }
            }
        }
    }
}

// ----

.sidebar-menu a {
    padding-right: 15px;
}

.treeview .treeview-icon {
    top: 9px;
}

.card-img-background {
    height: 150px;
    background-size: cover;
    background-position: center center;
}

.card-img-background-default {
    height: 150px;
    padding-top: 1rem;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
}

.card-img-background-alert {
    height: 150px;
    padding-top: 1rem;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
}

.card-icon {
    width: 50px;
    padding: 0.5rem;
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 5px 0 0 0;
}

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip:not([role="tooltip"]) {
    opacity: 0;
    z-index: 99;
    color: white;
    width: 190px;
    display: block;
    font-size: 11px;
    padding: 5px 10px;
    border-radius: 3px;
    text-align: center;
    background: rgb(255, 152, 0);
    border: 1px solid rgb(255, 152, 0);
    box-shadow: 0 0 3px rgb(255, 152, 0);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    position: absolute;
    right: -80px;
    bottom: 40px;
}

.tooltip:not([role="tooltip"]):before,
.tooltip:not([role="tooltip"]):after {
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgb(255, 152, 0);
    position: absolute;
    bottom: -10px;
    left: 43%;
}

.tooltip-container:hover .tooltip:not([role="tooltip"]),
a:hover .tooltip:not([role="tooltip"]) {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.card-order {
    width: 2em;
    height: 2em;
    background-color: #ffffff;
    display: grid;
    place-content: center;
    font-weight: bold;
    position: absolute;
    top: 1rem;
    border-radius: 0 5px 5px 0;
}

.alert-question {
    font-weight: bold;
    height: 4rem;

    img {
        width: 2rem;
        padding-top: -1rem;
    }
}

.loginForm {
    .checkbox {
        background-color: var(--color-primary-lightest);
        height: 3rem;
        border: 2px solid transparent;
        border-radius: 5px;
        box-shadow: #e3e7edc2 1px 1px 2px 2px;

        input[type="checkbox"] {
            background: var(--color-primary);
            border: none;
            border-radius: 0.2rem;
            color: white;
            width: 1.5rem;
            height: 1.5rem;
            margin-top: 0.5rem;
            margin-left: 1rem;

            ~ p {
                margin-top: 0.5rem;
                color: var(--color-secondary-darkest);
                float: right;
                padding-left: 1em;
            }
        }
    }
}

.button {
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    font-size: 18px;
    cursor: pointer;
    margin: 12px 5px;
    height: 3rem;
}

.buttonPrimary {
    color: var(--color-primary-lightest);
    background: var(--color-secondary);
    border: 2px solid var(--color-secondary);
  }

  .buttonPrimary:hover {
    background: var(--color-secondary-darker);
  }

  .buttonWhite {
    color: var(--color-secondary);
    background: var(--color-primary-lightest);
    border: 2px solid var(--color-secondary);
    text-align: center;
  }

  .buttonWhite:hover {
    background: var(--color-secondary);
    color: var(--color-primary-lightest);
  }

.groupButton {
    text-align: center;

    a {
        width: 100%;
        text-decoration: none;
    }
}

.checkBoxRegister {
    .form-group {
        .form-check {
            background-color: var(--color-primary-lightest);
            height: 3rem;
            border: 2px solid transparent;
            border-radius: 5px;
            box-shadow: #e3e7edc2 1px 1px 2px 2px;

            input[type="checkbox"] {
                background: var(--color-primary);
                border: none;
                border-radius: 0.2rem;
                color: white;
                width: 1.5rem;
                height: 1.5rem;
                margin-top: 0.5rem;
                margin-left: 1rem;
                margin-right: 1rem;

                ~ label {
                    margin-top: 0.5rem;
                    margin-left: 2rem;
                    color: var(--color-secondary-darkest);
                    padding-left: 1em;
                }
            }
        }
    }
}

.titleForm {
    background-color: var(--color-primary-lightest);
    width: 50%;
    height: 3rem;
    border: 2px solid transparent;
    border-radius: 5px;
    box-shadow: #e3e7edc2 1px 1px 2px 2px;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 0.8rem;
    padding-left: 2rem;
}

.cardEmailSend {
    background-color: var(--color-primary-lightest);
    margin: auto;
    margin-top: 3rem;
    padding: 3rem;
    border: 2px solid transparent;
    border-radius: 5px;
    box-shadow: #e3e7edc2 1px 1px 2px 2px;
    width: 50%;
}

@media screen and (max-width: 600px) {
    .titleForm {
        width: 100%;
    }

    .cardEmailSend {
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .titleRegister {
        width: 80%;
    }

    .cardEmailSend {
        width: 80%;
    }
}

.viewPdf {
    background: var(--color-primary-lightest);
    width: 100%;

    .iframePdf {
        width: 90%;
        height: 600px;
        margin: 5%;
        background-color: var(--color-primary-lightest);
    }
}

.containerVideo {
    background: var(--color-primary-lightest);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;

    .video {
        width: 50%;
        margin-bottom: 1rem;
        margin-top: 1rem;
        margin-left: 1rem;
        border-radius: 15px;
    }
}

.activeAccount {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 100%;
    margin: auto;
    padding: initial;

    .contentAccount {
        background: var(--color-primary-lightest);
        padding: 2rem;
        border-radius: 1rem;
        width: 100%;

        form {
            width: 90% !important;
        }

        img {
            width: 60%;
        }
    }
}

.form-panel-header {
    box-shadow: none;
}

.form-panel-hide-legend {
    legend {
        display: none;
    }
}

.panel-user-extra-fields {
    .form-panel-body > .row > div:not(.flex-fill) {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
    }

    .user-extra-fields {
        .form-widget {
            flex: 1;

            #User_extra {
                display: flex;
                flex-flow: row wrap;

                & > div {
                    width: 33.3%;
                }

                div.flex-fill {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .user-extra-fields {
            .form-widget {
                #User_extra {
                    & > div {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.form-check.form-switch > label {
    padding: 0 0 0 35px;
}

.course-seasons td.season-name {
    cursor: move;
}

.card {
    border: 0;
    box-shadow: $shadow-elevation-1;

    .card-body {
        background: var(--color-neutral-lightest);
    }

    .card-chapter-success {
        --local-background-color: var(--color-primary);
    }

    .card-chapter-warning {
        --local-background-color: #{$color-orange};
    }

    :is(.card-chapter-success, .card-chapter-warning) {
        // background-color: var(--local-background-color);
        // color: #ffffff;
        height: 2px;

        .icon {
            color: var(--local-background-color);
            margin-right: 2rem;
            font-size: 1.5rem;
            margin-top: 0.5rem;
        }
    }

    .icon {
        color: #fff;
        width: 35px;
        border-radius: 50%;
        text-align: center;
        margin-left: auto;
        position: absolute;
        right: 2rem;
        top: 0;
        height: 35px;
        display: grid;
        place-content: center;
        z-index: 2;
    }
}

.icon-background-warning {
    background-color: $color-orange;
}

.icon-background-success {
    background-color: var(--color-primary);
}

.cursor-pointer {
    cursor: pointer;
}

#ea-index-Course
.wrapper
.content-wrapper
.content
#main
.content-panel
.content-panel-body
table
tbody
tr
td:nth-child(4)
a {
    pointer-events: none;
    cursor: default;
    color: rgb(18, 19, 19);
}

[v-cloak] {
    display: none;
}

#exportModal,
#detailsModal {
    backdrop-filter: blur(2px);

    .modal-header {
        background-color: var(--color-neutral-darker);

        .close,
        .modal-title {
            color: #fff;
            text-shadow: none;
        }
    }
}

#exportContainer {
    justify-content: space-between;
    align-items: center;

    & > div {
        width: fit-content;
    }

    div.p-4 {
        padding: 0 1rem !important;
    }
}

#flash-messages {
    width: auto;
    top: 0rem;
    right: 0rem;
    z-index: 3;
    position: fixed;
    box-shadow: none;
    background: none;
    padding: 1rem;
    max-width: 500px;

    .alert-success {
        color: #fff;
        background-color: $color-success;
        border-color: $color-success;
        text-align: center;
    }

    .alert-dismissible .btn-close {
        padding: 1.25rem 1rem;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        bottom: 0;
        margin: auto;
    }
}

.use-sidebar-width {
    width: var(--sidebar-max-width);
}

.dropdown-menu {
    padding: 2px;

    .dropdown-item {
        margin-bottom: 2px !important;

        &:last-child {
            margin-bottom: 0 !important;
        }

        &.action-delete:hover {
            background: #bf2231;
        }
    }
}

.v-toast {
    position: fixed;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2em;
    overflow: hidden;
    z-index: 2000 !important;
    pointer-events: none;
}

.itinerary-courses-progress {
    .list-of-courses {
        list-style: none;

        li {
            display: flex;
            align-items: center;
            gap: $spacing-m;
            padding: $spacing-xs 0;
            border-bottom: 1px solid var(--color-neutral-mid);

            &.unstarted {
                filter: grayscale(0.8);
                opacity: 0.5;
            }

            .course-name {
                font-weight: 500;

                & + span {
                    margin-left: auto;

                    &:not(.badge) {
                        .finished {
                            color: var(--color-neutral-mid-darker);
                            font-size: var(--font-size-s);
                            margin-right: 0.5rem;
                        }

                        &:not(.badge) {
                            color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }
}

#modal-filters .modal-title {
    color: #dcdfe3 !important;
    font-size: var(--font-size-base);
}

.user-details {
    display: flex;
    flex-flow: row;
    gap: 1rem;
    .user-image {
        width: 320px;
        height: 320px;

        img {
            width: 100%;
            height: 100%;
        }
    }
    .details {
        flex-grow: 1;
    }
}

.setting label small {
    color: var(--color-neutral-mid-darker);
    font-style: italic;
}


.user-details {
    display: flex;
    flex-flow: row;
    gap: 1rem;
    .user-image {
      width: 320px;
      height: 320px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .details {
      flex-grow: 1;
    }
  }

  .userAvatar {
    position: relative;
    overflow: hidden;
    object-position: center;
    object-fit: cover;
    border-radius: 100%;

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      top: -5%;
      left: -5%;
      height: 110%;
      width: 110%;
      background: url("../images/admin/noavatar.svg") center;
      background-size: cover;
    }
  }

  .line-break-anywhere {
    overflow-wrap: break-word;
  }

  *::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  *::-webkit-scrollbar-track {
    box-shadow: inset -3px -6px 6px rgba(0,0,0,0.1);
    &:not(div, select, option) {
      background-color: var(--color-neutral-lightest);
    }
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 3px;
    outline: none;
  }



@media (min-width: 992px) {
    table.datagrid td:not(:has(div)),
    table.table td:not(:has(div)) {
        max-width: 7.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    table.datagrid td[title] {
        cursor: help;
    }
}
