.results {
    margin-bottom: 1rem;

    .header {
        display: flex;
        align-items: center;
        padding: 0.4rem 1rem;
        margin: 0 0 1rem 0;
        border: 1px solid #e9ecef;
        border-radius: 0.4rem;

        h3 {
            margin: 0;
            padding: 0;
        }

        .results-info {
            font-size: 1rem;
            margin-left: 0.4rem;
        }

        .toggle-questions {
            margin-left: auto;
            cursor: pointer;
        }
    }

    .questions {
        margin-left: 1rem;

        .question {

            margin-bottom: 1rem;

            .roulette-options{
                display: flex;
                justify-content: start;
                align-items: center;
            }

            .letter{
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;

                text-transform: uppercase;
                //font-family: $font-family-secondary;
                border-radius: 50px;
                background: var(--local-background);
                color: var(--local-color);
                box-shadow: var(--local-shadow);
                transition: all .3s ease-in-out;

                &.state{
                    &--default{
                        $color: adjust-color(  hsl(172, 38%, 57%), $saturation: -15%, $lightness: 10%);
                        --local-background: hsl(210, 7%, 56%);
                        --local-color: #fff;
                        --local-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.12), 0 1px 2px hsla(0, 0%, 0%, 0.24);
                    }
                }
            }

            .answers {
                .answer {
                    display: flex;
                    align-items: center;
                    margin: 0.4rem auto 0.4rem 1rem;
                    padding: 0.4rem;
                    border-radius: 0.4rem;

                    &.selected {
                        background-color: #dbe4fb;
                    }

                    .answer-option {

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-transform: uppercase;
                        font-weight: bold;
                        transition: all 0.25s ease;
                        border: solid 1px #5680ee;
                        border-radius: 100%;
                        min-width: 1.5rem;
                        min-height: 1.5rem;
                        margin-right: 0.5rem;

                        &.correct {
                            color: #fff;
                            background-color: #00e68a;
                            border-color: #00e68a;
                        }

                        &.wrong {
                            color: #fff;
                            background-color: #b12d2d;
                            border-color: #b12d2d;

                        }
                    }
                }
            }
        }
    }
}
