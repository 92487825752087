

:root {
    --color-primary: #{$color-primary};
    --color-primary-dark: #{$color-primary-dark};
    --color-primary-darker: #{$color-primary-darker};
    --color-primary-darkest: #{$color-primary-darkest};
    --color-primary-light: #{$color-primary-light};
    --color-primary-lighter: #{$color-primary-lighter};
    --color-primary-lightest: #{$color-primary-lightest};

    --color-secondary: #{$color-secondary};
    --color-secondary-light: #{$color-secondary-light};
    --color-secondary-lighter: #{$color-secondary-lighter};
    --color-secondary-lightest: #{$color-secondary-lightest};
    --color-secondary-darker: #{$color-secondary-darker};
    --color-secondary-darkest: #{$color-secondary-darkest};

    --color-tertiary: #{$color-tertiary};

    --color-neutral-light: #{$color-neutral-light};
    --color-neutral-lighter: #{$color-neutral-lighter};
    --color-neutral-lightest: #{$color-neutral-lightest};
    --color-neutral-mid: #{$color-neutral-mid};
    --color-neutral-mid-light: #{$color-neutral-mid-light};
    --color-neutral-mid-dark: #{$color-neutral-mid-dark};
    --color-neutral-mid-darker: #{$color-neutral-mid-darker};
    --color-neutral-dark: #{$color-neutral-dark};
    --color-neutral-darker: #{$color-neutral-darker};
    --color-neutral-darkest: #{$color-neutral-darkest};

    // dashboard
    --color-dashboard-1: #{$color-dashboard-1};
    --color-dashboard-2: #{$color-dashboard-2};
    --color-dashboard-3: #{$color-dashboard-3};
    --color-dashboard-4: #{$color-dashboard-4};
    --color-dashboard-5: #{$color-dashboard-5};

    --color-app: #{$color-app};

    --color-success: #{$color-success};
    --color-error: #{$color-error};
    --color-warning: #{$color-warning};
    --color-info: #{$color-info};

    --color-orange: #{$color-orange};

    --color-grey-darkest: #{$color-grey-darkest};

    --base-border-color: #{$base-border-color};
    --base-border-color-active: #{$base-border-color-active};




    --sidebar-max-width: 250px;
}
